@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
@layer base {
    .hum-container {
        @apply mx-auto max-w-[1000px];
    }

    .center-content {
        @apply flex h-screen w-screen flex-col items-center justify-center;
    }

    /* Checkbox and Radio */
    [type="checkbox"]:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%232A313F' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    }
    [type="radio"]:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%232A313F' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    }

    @font-face {
        font-family: "Supera Gothic Bold";
        src: url(./fonts/Supera_Gothic/SuperaGothic-Bold.otf);
        font-weight: 600;
    }
    @font-face {
        font-family: "Supera Gothic Extra Bold";
        src: url(./fonts/Supera_Gothic/SuperaGothic-ExtraBold.otf);
        font-weight: 700;
    }
    @font-face {
        font-family: "Supera Gothic Black";
        src: url(./fonts/Supera_Gothic/SuperaGothic-Black.otf);
        font-weight: 800;
    }
    /* Utilities */
    .flex-between {
        @apply flex justify-between;
    }

    /* Color Utilities */
    .bg-default {
        @apply bg-white dark:bg-slate-800;
    }

    .bg-secondary {
        @apply bg-slate-50 dark:bg-slate-900;
    }

    .text-color {
        @apply text-slate-900 dark:text-white;
    }

    .text-inverted {
        @apply text-white dark:text-slate-900;
    }

    .fill-default {
        @apply text-slate-900 dark:text-white;
    }

    .fill-subdued {
        @apply fill-gray-500;
    }

    .stroke-subdued {
        @apply stroke-gray-500;
    }

    .subdued {
        @apply text-gray-500;
    }

    .text-disabled {
        @apply text-gray-400;
    }

    .border-default {
        @apply border-slate-300 dark:border-slate-700;
    }

    /* Text Utilities */
    .display-l {
        @apply cursor-default font-supera-black text-5xl leading-[56px] tracking-[0.0016em];
    }

    .display-m {
        @apply cursor-default font-supera-black text-[2rem] leading-10 tracking-[0.0016em];
    }

    .display-s {
        @apply cursor-default font-supera-black text-2xl leading-8 tracking-[0.0016em];
    }

    .pageheading {
        @apply cursor-default font-supera-extra-bold text-xl leading-8 tracking-[0.0016em];
    }

    .heading-l {
        @apply cursor-default font-inter text-lg font-semibold leading-6 tracking-normal;
    }

    .heading-m {
        @apply cursor-default font-inter text-base font-semibold leading-6 tracking-normal;
    }

    .heading-s {
        @apply cursor-default font-inter text-sm font-semibold leading-5 tracking-normal;
    }

    .subheading {
        @apply cursor-default font-inter text-[0.688rem] font-medium uppercase leading-6 tracking-[1px];
    }

    .body-l {
        @apply cursor-default font-inter text-lg font-normal leading-7 tracking-normal;
    }

    .body-m {
        @apply cursor-default font-inter text-base font-normal leading-6 tracking-normal;
    }

    .body-s {
        @apply cursor-default font-inter text-sm font-normal leading-5 tracking-normal;
    }

    .ui-element {
        @apply cursor-default font-inter text-sm font-normal leading-6 tracking-normal;
    }

    .ui-label {
        @apply cursor-default font-inter text-xs font-normal tracking-normal;
    }

    .body-micro {
        @apply cursor-default font-inter text-xs font-normal leading-4 tracking-normal;
    }

    .text-button {
        @apply cursor-pointer font-inter text-sm font-medium leading-6 tracking-normal;
    }

    .lozenge {
        @apply cursor-default font-inter text-xs font-medium uppercase leading-4 tracking-[0.16px];
    }

    .text-link {
        @apply cursor-pointer font-inter text-sm font-normal leading-5 tracking-normal text-brand-400 hover:text-brand-400 dark:text-brand-300;
    }

    /* Semantic HTML tags */

    * {
        @apply text-color;
    }

    body {
        @apply bg-default;
        @apply scrollbar-thin scrollbar-track-[#282828] scrollbar-thumb-[#494949];
    }

    label {
        @apply ui-element;
    }

    a {
        @apply text-link;

        > svg {
            @apply text-link dark:fill-brand-300 dark:stroke-brand-300;
        }
    }

    input,
    input[type="text"] {
        @apply bg-secondary focus:bg-secondary;
        @apply mb-1 mt-2 w-full p-2 pl-3 text-white placeholder:bg-transparent disabled:bg-white/20;
        @apply border-default rounded border outline-none;
        @apply text-color autofill:text-color;
        @apply placeholder:text-slate-500 disabled:placeholder:text-white/20;
        @apply focus:ring-transparent focus:placeholder:text-transparent;
    }

    input:-webkit-autofill {
        box-shadow: 0 0 0 40px theme(colors.slate.900) inset !important;
        -webkit-text-fill-color: theme(colors.white) !important;
    }

    input:-webkit-autofill:disabled,
    input:-webkit-autofill-selected:disabled {
        box-shadow:
            /* Primary background shadow */
            inset 0 0 0 40px rgba(255, 255, 255, 0.2),
            inset 0 0 0 40px theme(colors.slate.900); /* Secondary shadow with white at 20% opacity */
        -webkit-text-fill-color: rgba(255, 255, 255, 0.2);
        cursor: not-allowed !important; /* Disabled cursor */
    }

    p {
        @apply body-m;
    }

    h1 {
        @apply display-s sm:display-l;
    }

    h2 {
        @apply pageheading sm:display-m;
    }

    h3 {
        @apply pageheading sm:display-s;
    }

    h4 {
        @apply heading-l sm:pageheading;
    }
    button[aria-haspopup="listbox"] {
        @apply dark:bg-gray-600 dark:disabled:bg-white/20;
        @apply rounded py-2 pl-4 pr-10 text-left;
        @apply text-color cursor-pointer;
        &.secondary {
            @apply bg-secondary disabled:cursor-not-allowed dark:disabled:bg-white/20;
            @apply border-default rounded border outline-none;
        }
    }

    input[role="combobox"] + button[aria-haspopup="listbox"] {
        @apply border-none py-0 pl-0 pr-2;
        @apply bg-transparent hover:bg-transparent;
        @apply cursor-pointer;
    }

    button:not([aria-haspopup="listbox"]),
    button[type="button"]:not([aria-haspopup="listbox"]),
    button[type="submit"],
    button[type="reset"],
    a[role="button"]:not(.text-link) {
        @apply bg-brand-500 enabled:hover:bg-brand;
        @apply disabled:dark:text-disabled disabled:bg-brand-400 disabled:text-gray-200;
        @apply max-h-8 px-2.5 py-1.5;
        @apply rounded shadow-sm;
        @apply text-button text-white !important;
        @apply inline-flex items-center justify-center gap-x-1.5;
        @apply disabled:cursor-not-allowed;
        @apply outline-none;

        &.secondary {
            @apply bg-white enabled:hover:bg-gray-50;
            @apply text-color;
            @apply dark:bg-gray-600 dark:enabled:hover:bg-gray-500;
            @apply shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-0;
        }

        &.destructive {
            @apply bg-red-600 enabled:hover:bg-red-700;
            @apply disabled:bg-red-400 disabled:dark:bg-gray-700;
            @apply disabled:dark:text-disabled disabled:text-gray-200;
        }

        &.quiet {
            @apply bg-transparent stroke-white hover:bg-transparent disabled:bg-transparent;
            @apply text-white shadow-none hover:text-slate-200 !important;
        }

        &.xs {
            @apply max-h-6 px-2 py-1 text-xs;
        }

        &.sm {
            @apply max-h-7 px-2 py-1;
        }

        &.md {
            @apply max-h-8 px-2.5 py-1.5;
        }

        &.lg {
            @apply max-h-9 px-3 py-2;
        }

        &.xl {
            @apply max-h-10 px-3.5 py-2.5;
        }
    }

    a[role="button"]:hover {
        @apply bg-brand !important;
    }

    tbody,
    dd {
        @apply body-s;
    }

    dl:not(.rows) {
        dt {
            @apply subheading mb-1;
        }
    }

    dl.rows {
        @apply grid grid-cols-[auto_1fr] gap-y-4;

        dt {
            @apply heading-s border-default mb-0 w-[144px] border-b pr-8;
        }

        dd {
            @apply border-default border-b pb-4;
        }
    }
}

@layer components {
    /* React router navlinks */
    .navlink-item {
        position: relative;
        text-decoration: none;
    }
    .navlink-item.active::after {
        content: "";
        position: absolute;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: theme("colors.brand-500");
        transform: translateY(-3px);
    }

    .badge {
        @apply lozenge w-fit rounded-full px-[8px] py-[2px] text-center uppercase text-black;
    }

    .tooltip-container {
        @apply relative w-fit cursor-pointer;
    }

    .tooltip-container-flex {
        @apply tooltip-container flex items-center gap-1;
    }

    .tooltip {
        @apply bg-secondary absolute -top-2 left-full hidden min-w-[320px] -translate-x-4 -translate-y-full rounded px-4 py-2 shadow-tooltip ring-[0.5px] ring-gray-500 group-hover:inline;
    }

    .tooltip-bottom {
        @apply bg-secondary absolute -left-[300px] top-6 hidden min-w-[320px] translate-y-0 rounded px-4 py-2 shadow-tooltip ring-[0.5px] ring-gray-500 group-hover:inline;
    }

    .custom-scrollbar {
        scrollbar-width: thin;
        scrollbar-color: theme("colors.brand.400") theme("colors.slate.700");
        position: relative;
    }

    .custom-scrollbar::-webkit-scrollbar {
        width: 8px;
        -webkit-appearance: none;
        margin-right: 16px;
    }
    .custom-scrollbar::-webkit-scrollbar-track {
        background-color: theme("colors.slate.700");
        border-radius: 20px;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
        background-color: theme("colors.brand.400");
        border-radius: 20px;
        min-height: 40px;
    }

    .fade-container {
        opacity: 0;
        animation: fadeIn 0.5s forwards;
    }

    .fade-container.hide {
        animation: fadeOut 0.5s forwards;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}
